import { useEffect, useState } from 'react'
const IS_DEV = process.env.NODE_ENV === 'development'

const OrganizationLogo = ({ slug, styles = null, onCheck = null }) => {
  const [organizationLogo, setOrganizationLogo] = useState(null)

  const localFolder = `${CONFIG.assetsBaseURL}/images/organization-license-logos`
  const s3Route = `${CONFIG.cdnURL}/usercontent/orglogos`

  const orgLogosPath = IS_DEV ? localFolder : s3Route

  const logosInPublicFolder = [
    'astra-zeneca-2208-1',
    'purdue-university',
    'university-of-zurich',
    'zambon'
  ]

  const checkImageExists = () => {
    if (!slug) return setOrganizationLogo(null)

    const imageUrl = `${orgLogosPath}/${slug}${IS_DEV ? '.png' : ''}`

    // eslint-disable-next-line
    const image = new Image()
    image.src = imageUrl

    image.onerror = () => {
      // validation for logos that aren't stored in S3, but exists in public folder
      if (logosInPublicFolder.includes(slug)) {
        const srcInPublicFoler = `${localFolder}/${slug}.png`
        return setOrganizationLogo(srcInPublicFoler)
      }

      setOrganizationLogo(null)

      if (onCheck) {
        onCheck(null)
      }
    }

    image.onload = () => {
      setOrganizationLogo(imageUrl)
      if (onCheck) {
        onCheck(imageUrl)
      }
    }
  }

  useEffect(() => {
    checkImageExists()
  }, [])

  return (
    <>
      {organizationLogo && (
        <img
          src={organizationLogo}
          alt='organization logo'
          className={styles}
        />
      )}
    </>
  )
}

export default OrganizationLogo
