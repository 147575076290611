// @ts-nocheck
import { getSessionStorage } from './get-session-storage'
const sessionStorage = getSessionStorage()
const IS_DEV = process.env.NODE_ENV === 'development'
const SERVER_SIDE = typeof window === 'undefined'
const DISABLED = (IS_DEV || SERVER_SIDE)

export const setup = () => {
  if (DISABLED) return

  const script = document.createElement('script')
  script.id = 'ze-snippet'
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${CONFIG.zendesk.key}`

  document.getElementsByTagName('head')[0].appendChild(script)

  script.onload = () => {
    hideWidget()
    setChatStatus(false)
  }
}

export const hideWidget = () => {
  if (!window?.zE) return

  window.zE('messenger', 'hide')
  setChatStatus(false)
}

export const showWidget = () => {
  if (!window?.zE) return

  window.zE('messenger', 'show')
  setChatStatus(true)
}

export const openChat = () => {
  if (!window?.zE) return

  window.zE('messenger', 'open')
}

export const closeChat = () => {
  if (!window?.zE) return

  window.zE('messenger', 'close')
}

export const getChatStatus = () => {
  const storedValue = JSON.parse(sessionStorage.getItem('isZendeskChatOpen'))
  return typeof storedValue === 'boolean' ? storedValue : false
}

const setChatStatus = (status) => {
  if (!sessionStorage) return
  sessionStorage.setItem('isZendeskChatOpen', JSON.stringify(status))
}

export const resetChatStatus = () => {
  sessionStorage.removeItem('isZendeskChatOpen')
}
